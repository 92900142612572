import React, { useState } from 'react';
import { useFormik } from 'formik';
import CustomContainer from '../components/UI/layout';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../context/auth';
import { useHistory } from 'react-router-dom';
import {connect} from "react-redux";
import {createTrainer, fetchTrainers} from "../actions/trainers-action";


export const NewUser = ({ isLoading,createTrainer}) => {
  const { authToken } = useAuth();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const { goBack } = useHistory();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      admin: authToken
    },
    async onSubmit(values) {
      try {
        setError(null);
        setSuccess(null);
        createTrainer(values)
        fetchTrainers()
      } catch (e) {
        setError(e.response.data.error);
      }
    }
  });



  const classes = useStyles();

  return (
    <CustomContainer onBack={goBack}>
      <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
        <TextField
          id="name"
          name="name"
          type="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Name"
          autoComplete="name"
          autoFocus
        />
        <TextField
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email"
          autoComplete="current-email"
        />
        <TextField
          id="password"
          name="password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Create Trainer
        </Button>
        <p className={classes.successMessage}>{success}</p>
        <p className={classes.errorMessage}>{error}</p>
        {isLoading ? <CircularProgress color="secondary" /> : ''}
      </form>
    </CustomContainer>
  );
};

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  successMessage: {
    color: 'green'
  },
  errorMessage: {
    color: 'red'
  }
}));


function mapStateToProps(state) {
  return {
    isLoading: state.trainers.isLoading
  }
}


export default connect(mapStateToProps, {createTrainer})(NewUser);

