import React, {Fragment,useEffect} from 'react';
import IconButton from '@material-ui/core/Button';
import {
  Link,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import config from "../../../config";
import BuildIcon from "@material-ui/icons/Build";
import {connect} from "react-redux";
import * as trainerActions from "../../../actions/trainers-action";
import * as leadsActions from "../../../actions/leads-action";
import DeleteDialog from "../../UI/deleteDialog";
import {useAuth} from "../../../context/auth";



export const ItemAction = (props) => {

  const {leadsAssignTrainer,fetchTrainers,item,type} = props
  const { authToken } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const onOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  const onOpen2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const onClose2 = () => {
    onClose();
    setAnchorEl2(null);
  };

  const onSelected = async (trainerId,trainerName,clientId) => {
    let values = {};
    try{
      values = {user:trainerId,client:clientId,trainerName:trainerName}
      leadsAssignTrainer(values)
      fetchTrainers()
      onClose();
      onClose2();
    }catch (e){
      console.log(e)
    }
  };

  const openLead = () => {
    let interactiveLogin = `${config.interactiveLoginURL}/${authToken}`;
    window.open( interactiveLogin)
  }

  return (
      <Fragment>
        <Fragment>
          <Tooltip title={type + " actions"}>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={onOpen}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
          <Menu
              anchorEl={anchorEl}
              keepMounted
              transformOrigin={{
                vertical: 15,
                horizontal: -50,
              }}
              open={!!anchorEl}
              onClose={onClose}
          >
            <MenuItem>
              <Link href={"#"} style={{width:"100%"}} onClick={openLead} rel="noreferrer" >
                Visit {type} list
              </Link>
            </MenuItem>

            <MenuItem>
              <div>
                <DeleteDialog
                    name={item.name}
                    itemId={item.id}
                    title="Delete"
                    itemType={type}
                />
              </div>
            </MenuItem>
          </Menu>
        </Fragment>
      </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.clients.clientChangeLoading
  }
}

export default connect(mapStateToProps, {...trainerActions,...leadsActions})(ItemAction);
