import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as trainerActions from '../actions/trainers-action';
import CustomContainer from '../components/UI/layout';
import Loader from "../components/UI/loader";
import UniversalTable from "../components/UI/table/universalTable";
import AddIcon from "@material-ui/icons/Add";
import {Fab} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const TrainersContainer = ({ trainers, fetchTrainers, isLoading }) => {

  useEffect(() => {
    fetchTrainers()
    if(trainers.length === 0){
    }
  }, []);

  const classes = useStyles();
  const history = useHistory();

  const content = isLoading ? (
    <Loader color="secondary" />
  ) : (
    <UniversalTable
        items={trainers}
        tableTitles={["name","# clients","Go to trainer"]}
        tableType="trainer"
    />
  );


  return (
    <CustomContainer title={"All trainers"}>
      {content}
      <Fab onClick={() => history.push('/new')} className={classes.fab} color="primary" aria-label="add">
        <AddIcon/>
      </Fab>
    </CustomContainer>
  );
};

function mapStateToProps(state) {
  return {
    trainers: state.trainers.trainers,
    isLoading: state.trainers.isLoading
  }
}

export default connect(mapStateToProps, {...trainerActions})(TrainersContainer);


const useStyles = makeStyles(theme => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  }
}));
