import React, {useState,useEffect} from 'react';
import { TextField} from '@material-ui/core';
import {connect} from "react-redux";
import * as leadsActions from "../../../actions/leads-action";
import * as clientsActions from "../../../actions/clients-action";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import {WAIT} from "../../../constants";


const SearchTable  = ({searchLeads,searchClients,searchLeadsCount, searchClientCount,tableType}) =>{


    const [query, setQuery] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(tableType === "lead"){
                searchLeadsCount(query)
                searchLeads(query)
            }else {
                searchClientCount(query)
                searchClients(query)
            }
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [query])

    const handleClear = () => {
        setQuery('')
    }

    return (
        <div style={{display:"flex",alignItems:"center"}}>
            <TextField
                style={{width:"30%"}}
                id="query"
                name="query"
                type="text"
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                label="Search by email or name"
            />
            {query ? <CloseSharpIcon onClick={handleClear}/> : null }
        </div>
    )
}
function mapStateToProps(state) {
    return {
        leads: state.leads.leads,
        leadsQuery: state.leads.q,
        clientsQuery: state.clients.q,
        clients:state.clients.clients,
        offset:state.leads.offset
    }
}

export default connect(mapStateToProps, { ...leadsActions,...clientsActions })(SearchTable);


