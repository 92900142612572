import { combineReducers } from 'redux';
import trainers from './trainers-reducer';
import leads from './leads-reducer'
import clients from './clients-reducer'


export default combineReducers({
  trainers: trainers,
  clients:clients,
  leads:leads,
  global: state => state || {}
});
