import React, {useEffect, useState} from 'react';
import {Fade, Grow, Snackbar, Typography} from '@material-ui/core';
import {Alert} from "@material-ui/lab";
import {connect} from "react-redux";
import * as leadsActions from "../../../actions/leads-action";
import * as clientsActions from "../../../actions/clients-action";
import styles from './scss/main.module.css'

const AlertMsg = ({clearLeadResponse,clearClientResponse, response,responseStatus}) =>{

    const handleClose = () =>{
        clearLeadResponse();
        clearClientResponse();
    }

    return (
        <Snackbar className={styles.snackBar} open={responseStatus} autoHideDuration={4000} TransitionComponent={Fade} transitionDuration={600} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
            <Alert className={styles.alertMsg} onClose={handleClose} severity="success" variant="filled">
                {response}
            </Alert>
        </Snackbar>
    );
}

function mapStateToProps ( ){
    return{}
}

export default connect(mapStateToProps, { ...leadsActions,...clientsActions })(AlertMsg);
