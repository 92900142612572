import React, {useState} from 'react';
import {TableRow, TableCell} from '@material-ui/core';
import styles from "./scss/main.module.css"
import Moment from "react-moment";
import Status from "./Status";
import LeadInfo from "./LeadInfo";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ItemAction from "../common/actions/ItemAction";
import ChangeTrainerAction from "../common/actions/ChangeTrainerAction";
import ManageActions from "../common/actions/index";
import 'react-slidedown/lib/slidedown.css'
import SlideDown from "react-slidedown";
import {useAuth} from "../../context/auth";

const Lead = ({onChooseLeadAction,trainers,item,...props}) =>{
    const [showContent,setShowContent] = useState(false)
    const {authToken} = useAuth();
    const showContentLead = () =>{
        setShowContent(!showContent)
    }

    const answeredQuestionnaire = item.client && item.client.answeredQuestionnaire
    const isActive = item.client && item.client.active
    const clientTrainer = item.client && item.client.trainer.name
    const isLoggedInTrainer = trainers.find(trainer => trainer.token === authToken)
    const readyToBeAssigned = isLoggedInTrainer && isLoggedInTrainer.name === clientTrainer
    return (
        <>
            <TableRow className={showContent?styles.shown:""}>
                <TableCell className={styles.leadName}>
                    <div onClick={showContentLead}>
                        {item.name} { !showContent ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/> }
                    </div>
                </TableCell>
                <TableCell>
                    <Moment format="DD. MMMM, HH:ss">{item.createdAt.date}</Moment>
                </TableCell>
                <TableCell>
                    <Status status={readyToBeAssigned && answeredQuestionnaire ? 9: item.status}/>
                </TableCell>
                <TableCell>
                    {
                        answeredQuestionnaire?
                            (
                                <ManageActions>
                                    <ChangeTrainerAction
                                        item={item}
                                        clientId={item.client.id}
                                        currentTrainer={item.client.trainer.name}
                                        availableTrainers={trainers}
                                        type={"lead"}
                                    />
                                </ManageActions>
                            ):clientTrainer
                    }
                </TableCell>
                <TableCell>
                    <ManageActions>
                        <ItemAction
                            item={item}
                            currentTrainer={item.user}
                            clientId={item.clientId}
                            availableTrainers={trainers}
                            type={"lead"}
                        />
                    </ManageActions>
                </TableCell>
            </TableRow>
            {showContent ? (<LeadInfo item={item}/>):null}

        </>
    );
}
export default Lead;
