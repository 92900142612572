import React, {Fragment,useState} from 'react';
import {makeStyles, Menu,
  MenuItem, Select,
} from '@material-ui/core';
import styles from "../scss/main.module.css"
import {connect} from "react-redux";
import * as trainerActions from "../../../actions/trainers-action";
import * as clientActions from "../../../actions/clients-action";
import * as leadsActions from "../../../actions/leads-action";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 0,
    padding:"0 10px",
    minWidth: "8rem"
  },
}));

export const ChangeTrainerAction = (props) => {
  const classes = useStyles();
  const {
    availableTrainers,
    clientId,
    currentTrainer,
    item,
    type,
    clientChangeTrainer,
    leadsAssignTrainer,
    fetchTrainers} = props
  const [selectedTrainerName, setSelectedTrainerName] = useState(currentTrainer);

  const handleChange = async (event) => {
    /*Set target*/
    setSelectedTrainerName(event.target.value)
    /*Find id*/
    const trainerId = availableTrainers.find(trainer => trainer.name === event.target.value).id
    let values= {}
    values = {user:trainerId,client:clientId,trainerName:event.target.value}
    type === "lead" ? leadsAssignTrainer(values) : clientChangeTrainer(values)

    fetchTrainers()
  };

  return (
      <Fragment>
        {
          availableTrainers.length > 0 && selectedTrainerName ?
                <Select
                    id="demo-simple-select-helper"
                    value={selectedTrainerName}
                    onChange={handleChange}
                    className={styles.chooseTrainer}
                >
                  {
                    availableTrainers.map((trainer, i) =>  (
                        currentTrainer !== trainer.name ?
                            <MenuItem key={i} value={trainer.name}>{trainer.name}</MenuItem>:
                            <MenuItem key={i} disabled={true} value={trainer.name}>{trainer.name}</MenuItem>
                    ))
                  }
                </Select>
              :null
        }
      </Fragment>
  );
};

function mapStateToProps(state) {
  return {  }
}

export default connect(mapStateToProps, {...trainerActions,...leadsActions,...clientActions})(ChangeTrainerAction);
