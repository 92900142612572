import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { AuthContext, useAuth } from './context/auth';
import Login from './containers/login';
import AllTrainers from './containers/allTrainers';
import AllLeads from './containers/allLeads';
import AllClients from "./containers/allClients";
import Clients from './containers/trainerClients';
import NewUser from './containers/newUser';
import Header from './components/UI/Header/header';
import {connect} from "react-redux";
import * as trainerActions from "./actions/trainers-action";


const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authToken } = useAuth();

  return (
    <Route {...rest} render={(props) => (
      authToken && authToken !== "undefined"
        ? <><Header /><Component {...props} /></>
        :
          <Redirect to={{
            pathname: '/login',
            state: { from: props.location }
          }} />

    )} />
  )
};

export const App = ({ fetchTrainers,trainers }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
    /*Get trainers on load*/


  const setToken = token => {
    localStorage.setItem('token', token);
    setAuthToken(token);
  };

    useEffect( ()=> {
        if(authToken !== "undefined"){
            fetchTrainers();
        }
    },[fetchTrainers]);

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setToken}}>
      <div className="App">
        <header className="App-header">
          <Router>
           <div>
             <Route exact path="/login" component={Login} />
             <PrivateRoute exact path="/" component={AllTrainers} />
             <PrivateRoute exact path="/leads" component={AllLeads} />
             <PrivateRoute exact path="/clients" component={AllClients} />
             <PrivateRoute exact path="/new" component={NewUser} />
             <PrivateRoute exact path="/clients/:id" component={Clients} />
           </div>
         </Router>
        </header>
      </div>
    </AuthContext.Provider>
  );
};

function mapStateToProps(state) {
    return {
        trainers: state.trainers.trainers,
        isLoading: state.trainers.isLoading
    }
}
export default connect(mapStateToProps, { ...trainerActions })(App);
