import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import * as deleteClientAction from "../../actions/clients-action";
import * as deleteLeadAction from "../../actions/leads-action";
import * as trainerActions from "../../actions/trainers-action";

export const  DeleteDialog = ({deleteClientAction,deleteLeadAction,fetchTrainers,...props}) => {
    const [open, setOpen] = React.useState(false);

    const {itemId,title,name,itemType} = props

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleDelete = (itemId) => {
        let values = {}
        values={id:itemId,type:itemType}

        if(itemType === "client"){
            try {
                deleteClientAction(values)
                fetchTrainers()
            }catch (e){
                console.log(e,"error")
            }
        }else if(itemType === "lead"){
            try {
                deleteLeadAction(values)
            }catch (e){
                console.log(e,"error")
            }
        }

        setOpen(false);
    };

    return (
        <div>
            <div color="primary" onClick={handleClickOpen}>
                {title}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm the deletion of " + itemType}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       Do you want to delete the {itemType}: {name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        no
                    </Button>
                    <Button onClick={() => handleDelete(itemId)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {
    ...deleteClientAction,
    ...trainerActions,
    ...deleteLeadAction
})(DeleteDialog);

