import React from "react";
import styles from "./scss/main.module.css";
import {TableCell, TableRow} from "@material-ui/core";
import ClientInfo from "./ClientInfo";

export default function LeadInfo(props) {
    const {item} = props

    return(
        <TableRow className={styles.leadContent}>
            <TableCell colSpan="8">
                <div className={styles.inner}>

                    <div className={styles.clientInfo}>
                        <h2>Client Info</h2>
                        <ClientInfo item={item}/>
                    </div>

                    <div className={styles.leadInfo}>
                        <h2>Lead Info</h2>
                        <div>
                            <span><strong>Email:</strong> {item.email}</span>
                            <span><strong>Phone:</strong> {item.phone}</span>
                            <span><strong>Dialog:</strong></span>
                            <textarea defaultValue={item.dialogMessage ? item.dialogMessage : "No message"}/>
                        </div>
                    </div>
                </div>
            </TableCell>
        </TableRow>
    )
}
