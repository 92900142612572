import client from "./base";

/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getLeads() {
    return client.get('/leads');
}
/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getMoreLeads(values) {
    return client.get('/leads',{params: {offset:values.offset,q:values.q}});
}
/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCountLeads() {
    return client.get('/leads',{params: {count:true}});
}

/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSearchLeads(values) {
    return client.get('/leads',{params: {q:values}});
}
/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSearchLeadsCount(values) {
    return client.get('/leads',{params: {q:values,count:true}});
}

/**
 * @returns {Promise<Object>}
 * @param trainerId
 */
export const postLeadsAssignTrainer = async (values) =>{
    return client.post(`/trainer/move-client`, values).then(response => response.data)
        .then(data => ({message: data.msg}));
}

/**
 * @param string token
 */
export function deleteLead (values){

    return client.delete('', {
        params:{"id":values.id,"type":values.type}}).then(response => response.data)
        .then(data => ({message: data.msg}));
}
