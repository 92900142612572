import {
    LEAD_DELETE,
    LEADS_FETCH,
    LEADS_COUNT_FETCH,
    LEAD_CLEAR_RESPONSE,
    LEADS_SEARCH,
    LEADS_MORE_FETCH,
    LEADS_ASSIGN_TRAINER, OFFSET
} from "../constants";

const INITIAL_STATE = {
    leads: [],
    isLoading: false,
    isMoreLoading:false,
    leadsChangeLoading:false,
    offset:0,
    count:0,
    limit:0,
    q:'',
    response:'',
    responseStatus:false

};

export default function (state = INITIAL_STATE, {type, payload}) {
    switch (type) {

        case LEADS_FETCH.REQUEST:
            return {
                ...state,
                isLoading: true,
                offset: 0
            };
        case LEADS_FETCH.SUCCESS:
            return {
                ...state,
                leads: payload,
                isLoading: false
            };

        case LEADS_SEARCH.REQUEST:
            return {
                ...state,
                offset: 0,
                q:'',
                response: '',
                responseStatus:false
            };
        case LEADS_SEARCH.SUCCESS:
            return {
                ...state,
                ...payload
            };

        case LEADS_MORE_FETCH.REQUEST:
        {
            return {
                ...state,
                isMoreLoading: true,
                q:state.q,
                offset:state.offset,
                limit:0
            };
        }

        case LEADS_MORE_FETCH.SUCCESS:
        {
            return {
                ...state,
                ...payload
            };
        }
        case LEADS_COUNT_FETCH.REQUEST:
            return {
                ...state,
                count:state.count
            };
        case LEADS_COUNT_FETCH.SUCCESS:
        {
            return {
                ...state,
                count:payload.count
            };
        }

        case LEAD_DELETE.REQUEST:
        {
            return {
                ...state,
                deleteClientLoading: true,
                response: '',
                responseStatus:false
            };
        }

        case LEAD_DELETE.SUCCESS:
        {
            return {
                ...state,
                ...payload
            }
        }

        case LEADS_ASSIGN_TRAINER.REQUEST:
        {
            return {
                ...state,
                response:'',
                clientChangeLoading: true,
            };
        }

        case LEADS_ASSIGN_TRAINER.SUCCESS:
        {
            return {
                ...state,
                ...payload
            }
        }

        case LEAD_CLEAR_RESPONSE.SUCCESS:
        {
            return {
                ...state,
                responseStatus:false
            }
        }
        default:
            return state;
    }
}
