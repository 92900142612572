import React, {useCallback, useEffect} from 'react';
import CustomContainer from '../components/UI/layout';
import Loader from "../components/UI/loader";
import UniversalTable from "../components/UI/table/universalTable";
import {connect} from "react-redux";
import * as clientActions from '../actions/clients-action';
import SearchTable from "../components/UI/table/searchTable";
import ZenfitScore from "../components/Client/zenfitScore"


const ClientContainer = ({ trainers,clients,count,offset,query, response,isLoading,isMoreLoading,fetchMoreClients,responseStatus,fetchCountClients, fetchClients }) => {

    useEffect(() => {
        fetchClients();
        if(clients.length === 0) {
            fetchCountClients();
        }
    }, []);

    const wrapperEl = document.getElementById("contentContainer")
    const onScroll = useCallback((e) => {
        if ((wrapperEl.scrollHeight - wrapperEl.scrollTop) <= (wrapperEl.clientHeight+200) && !isMoreLoading) {
            if(offset < count || offset === count){
                isMoreLoading = true
                fetchMoreClients();
            }
        }
    });
    useEffect(() => {
        if(wrapperEl){
            wrapperEl.addEventListener('scroll', onScroll);
            return function cleanup() {
                wrapperEl.removeEventListener('scroll', onScroll);
            }

        }

    }, [onScroll])


    const content = isLoading ? (
            <Loader color="secondary" />
        ) : (
            <>
                <SearchTable tableType="client"/>
                <UniversalTable
                    items={clients}
                    trainers={trainers}
                    tableTitles={["name","Week","email","trainer","action"]}
                    tableType="client"
                    isMoreLoading={isMoreLoading}
                 />
            </>

        );

    return (
        <CustomContainer title={"All clients - "+ count} response={response} responseStatus={responseStatus}>
            {content}
        </CustomContainer>
    );
};


function mapStateToProps(state) {
    return {
        trainers: state.trainers.trainers,
        clients: state.clients.clients,
        query: state.clients.q,
        count:state.clients.count,
        isLoading: state.clients.isLoading,
        isMoreLoading: state.clients.isMoreLoading,
        offset:state.clients.offset,
        response:state.clients.response,
        responseStatus:state.clients.responseStatus
    }
}

export default connect(mapStateToProps, { ...clientActions })(ClientContainer);
