import React from 'react';
import {
    TableRow,
    TableCell, Button
} from '@material-ui/core';
import config from '../../config.js';

export default function Trainer(props) {
  const {
    id,
    name,
    clients,
    token
  } = props;

  let interactiveLogin = `${config.interactiveLoginURL}/${token}`;

  const goToTrainer = () => {
      window.open(interactiveLogin)
  }

  return (
      <TableRow>
        <TableCell align={"left"}>
          {name}
        </TableCell>
        <TableCell align={"left"}>{clients}</TableCell>
        <TableCell align="left" width={"20%"}>
            <Button variant="contained" onClick={goToTrainer} color="primary">
                Go to trainer
            </Button>
        </TableCell>
      </TableRow>
  );
}
