const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

function createRequestTypes(base) {
    return [REQUEST, SUCCESS, FAILURE].reduce((requestType, type) => {
        requestType[type] = `${base}_${type}`;
        return requestType;
    }, {});
}

// Trainers
export const TRAINERS_FETCH = createRequestTypes('TRAINERS_FETCH');
export const TRAINERS_CREATE = createRequestTypes('TRAINERS_CREATE');

export const CLIENTS_FETCH = createRequestTypes('CLIENTS_FETCH');
export const CLIENTS_SEARCH = createRequestTypes('CLIENTS_SEARCH');
export const CLIENTS_MORE_FETCH = createRequestTypes('CLIENTS_MORE_FETCH');
export const CLIENTS_CHANGE_TRAINER = createRequestTypes('CLIENTS_CHANGE_TRAINER');
export const CLIENTS_COUNT_FETCH = createRequestTypes('CLIENTS_COUNT_FETCH');
export const CLIENTS_SEARCHCOUNT_FETCH = createRequestTypes('CLIENTS_SEARCHCOUNT_FETCH');
export const CLIENT_DELETE = createRequestTypes('CLIENT_DELETE');
export const CLIENT_CLEAR_RESPONSE = createRequestTypes('CLIENT_CLEAR_RESPONSE');

/*Lead*/
export const LEADS_FETCH = createRequestTypes('LEADS_FETCH');
export const LEADS_COUNT_FETCH = createRequestTypes('LEADS_COUNT_FETCH');
export const LEADS_SEARCHCOUNT_FETCH = createRequestTypes('LEADS_SEARCHCOUNT_FETCH');
export const LEADS_SEARCH = createRequestTypes('LEADS_SEARCH');
export const LEADS_ASSIGN_TRAINER = createRequestTypes('LEADS_ASSIGN_TRAINER');
export const LEADS_MORE_FETCH = createRequestTypes('LEADS_MORE_FETCH');
export const LEAD_DELETE = createRequestTypes('LEAD_DELETE');
export const LEAD_CLEAR_RESPONSE = createRequestTypes('LEAD_CLEAR_RESPONSE');


/*Status*/
export const LEAD_STATUS = {
    '1': 'New',
    '8': 'Contacted',
    '2': 'In Dialog',
    '5': 'Offer Sent',
    '3': "Paid",
    '4': 'Lost',
    '9': "Assign trainer"
}

export const OFFSET = 30
export const WAIT = 500

/*Event names clients*/
export const CLIENT_COMPLETED = "client.completed"
export const MISSING_CHECKING = "client.missing_checkin"
