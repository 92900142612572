import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Paper,
  Button
} from '@material-ui/core';
import Title from "./title";
import AlertMsg from "./Alert/AlertMsg";

export default function Layout({onBack, ...props}) {

  const classes = useStyles();
  const {title,response,responseStatus} = props;
  return (
    <div id="contentContainer" className={classes.contentContainer}>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>
          <Title> {title} </Title>
          <Grid container spacing={3}>
            {
              onBack && (
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={onBack}>Go Back</Button>
                </Grid>
              )
            }
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {props.children}
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <AlertMsg response={response} responseStatus={responseStatus}/>
      </main>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop:"8rem",
  },
  contentContainer:{
    marginTop:"7rem",
    flexGrow:1,
    overflowY:"scroll",
    overflowX:"hidden",
    maxHeight:"85vh",
    position:"relative"
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    position:"relative"
  },
  container: {
    maxWidth:"1440px",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflowY:"hidden",
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
