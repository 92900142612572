import {
  LEADS_ASSIGN_TRAINER,
  LEAD_CLEAR_RESPONSE,
  LEAD_DELETE,
  LEADS_COUNT_FETCH,
  LEADS_FETCH,
  LEADS_MORE_FETCH,
  LEADS_SEARCH, WAIT, OFFSET, CLIENTS_MORE_FETCH, CLIENTS_COUNT_FETCH
} from '../constants';
import {
  deleteLead,
  getCountLeads,
  getLeads,
  getMoreLeads,
  getSearchLeads,
  getSearchLeadsCount,
  postLeadsAssignTrainer
} from "../api/leads";
import _ from 'lodash';
import {getMoreClients, getSearchClientsCount} from "../api/clients";



export const fetchLeads = () => {
  return async dispatch => {
    dispatch({ type: LEADS_FETCH.REQUEST });
    const response = await getLeads();
    dispatch({ type: LEADS_FETCH.SUCCESS, payload: response.data })
  }
}
export const fetchCountLeads = () => {
  return async dispatch => {
    dispatch({ type: LEADS_COUNT_FETCH.REQUEST });
    const response = await getCountLeads();
    dispatch({ type: LEADS_COUNT_FETCH.SUCCESS, payload: response.data })
  }
}

const debounceCount = _.debounce(getLeadsCountFunction,WAIT)

export const searchLeadsCount = (value) => {
  return async (dispatch) => {
    dispatch({ type: LEADS_COUNT_FETCH.REQUEST });
    return debounceCount(dispatch,value)
  }
}
async function getLeadsCountFunction(dispatch,value){
  const response = await getSearchLeadsCount(value);
  dispatch({ type: LEADS_COUNT_FETCH.SUCCESS, payload: response.data })
}


const debounceIt = _.debounce(getLeadsFunction,WAIT)

export const fetchMoreLeads = () => {
  return (dispatch,getState) => {
    dispatch({type: LEADS_MORE_FETCH.REQUEST});
    return debounceIt(dispatch,getState)
  }
}
async function getLeadsFunction(dispatch,getState){

  const body = {
    offset:getState().leads.offset+=OFFSET,
    q:getState().leads.q,
    limit:getState().leads.limit
  }
  const response = await getMoreLeads(body);

  const payloaded = {
    leads: [...getState().leads.leads.concat(response.data)],
    isMoreLoading: false,
    offset: getState().leads.offset,
  }
  dispatch({ type: LEADS_MORE_FETCH.SUCCESS, payload: payloaded})
}


export const searchLeads = (query) => {
  return async (dispatch, getState) => {
    dispatch({type: LEADS_SEARCH.REQUEST});

    const response = await getSearchLeads(query);

    const payloaded = {
      leads: response.data,
      q:query,
      offset: 30
    }
    dispatch({ type: LEADS_SEARCH.SUCCESS, payload: payloaded})
  }
}

export const deleteLeadAction = (values) => {
  return async (dispatch, getState) => {
    dispatch({ type: LEAD_DELETE.REQUEST });
    const response = await deleteLead(values);

    const payloaded = {
      leads: [...getState().leads.leads.filter(lead => lead.id !== values.id)],
      deleteClientLoading:false,
      count:getState().leads.count - 1,
      response: "Lead was deleted",
      responseStatus:true
    }
    dispatch({ type: LEAD_DELETE.SUCCESS, payload: payloaded })
  }
}


export const leadsAssignTrainer = (values) =>{
  return async (dispatch,getState) => {
    dispatch({ type: LEADS_ASSIGN_TRAINER.REQUEST });

    const response = await postLeadsAssignTrainer(values);

    const changedTrainerName = getState().leads.leads.find(lead => lead.client && lead.client.id === values.client ).client.trainer.name === values.trainerName

    const payloaded = {
      leads: [...getState().leads.leads],
      leadsChangeLoading: false,
      response: "Trainer was changed to '"+values.trainerName+"'",
      responseStatus: true
    }
    dispatch({ type: LEADS_ASSIGN_TRAINER.SUCCESS, payload: payloaded })
  }
}
export const clearLeadResponse = () => {
  return async dispatch => {
    dispatch({ type: LEAD_CLEAR_RESPONSE.SUCCESS})
  }
}


