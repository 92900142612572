import React,{useEffect,useCallback} from 'react';
import { connect } from 'react-redux';
import * as leadsActions from '../actions/leads-action';
import * as trainerActions from '../actions/trainers-action';
import CustomContainer from '../components/UI/layout';
import Loader from "../components/UI/loader";
import UniversalTable from "../components/UI/table/universalTable";
import SearchTable from "../components/UI/table/searchTable";
import AlertMsg from "../components/UI/Alert/AlertMsg";

const LeadContainer = ({leads,count, isLoading,isMoreLoading,offset,query,fetchMoreLeads,responseStatus, response,fetchLeads,fetchCountLeads,trainers }) => {

    useEffect(() => {
        fetchLeads();
        if(leads.length === 0){
            fetchCountLeads();
        }
    }, []);

    const wrapperEl = document.getElementById("contentContainer")
    const onScroll = useCallback((e) => {
        if ((wrapperEl.scrollHeight - wrapperEl.scrollTop) <= (wrapperEl.clientHeight+200) && !isMoreLoading) {
            if(offset < count || offset === count){
                isMoreLoading = true
                fetchMoreLeads()
            }
        }
    })
    useEffect(() => {
        if(wrapperEl){
            wrapperEl.addEventListener('scroll', onScroll);
            return function cleanup() {
                wrapperEl.removeEventListener('scroll', onScroll);
            }
        }
    }, [onScroll])


    const content = isLoading ? (
        <Loader color="secondary" />
        ) : (
            <>
                <SearchTable tableType="lead"/>
                <UniversalTable
                    items={leads}
                    trainers={trainers}
                    tableTitles={["name","received","status","trainer","manage"]}
                    tableType="lead"
                    isMoreLoading={isMoreLoading}
                />
            </>

    );


    return (
        <CustomContainer title={"All Leads - " + count} response={response} responseStatus={responseStatus}>
            {content}
        </CustomContainer>
    );
};

function mapStateToProps(state) {
    return {
        count:state.leads.count,
        leads: state.leads.leads,
        trainers:state.trainers.trainers,
        query:state.leads.q,
        isLoading: state.leads.isLoading,
        isMoreLoading: state.leads.isMoreLoading,
        offset:state.leads.offset,
        response:state.leads.response,
        responseStatus:state.leads.responseStatus,
    }
}

export default connect(mapStateToProps, { ...leadsActions,...trainerActions })(LeadContainer);
