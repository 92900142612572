import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../../context/auth';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
} from '@material-ui/core';
import styles from './scss/main.module.css'
import {NavLink} from "react-router-dom";

export default function User(props) {
  const { setAuthToken } = useAuth();
  const classes = useStyles();

  return (
      <>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Zenfit Gym
            </Typography>
            <Button color="inherit" onClick={() => setAuthToken()}>Logout</Button>
          </Toolbar>
          <div className={styles.tabNavigator}>
            <NavLink to="/" exact activeClassName={styles.active} > Trainers </NavLink>
            <NavLink to="/leads" exact activeClassName={styles.active} > Leads </NavLink>
            <NavLink to="/clients" exact activeClassName={styles.active} > Clients </NavLink>
          </div>
        </AppBar>
        <span className={styles.filler}/>
      </>
  );
};

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  }
}));
