import {TRAINERS_FETCH, TRAINERS_CREATE, TRAINER_DELETE} from '../constants';
import {getTrainers} from '../api/trainers';
import {postTrainer} from '../api/trainers';


export const fetchTrainers = () => {
  return async dispatch => {
    dispatch({ type: TRAINERS_FETCH.REQUEST });
    const response = await getTrainers();
    dispatch({ type: TRAINERS_FETCH.SUCCESS, payload: response.data })
  }
}

export const createTrainer = (values) => {
  return async dispatch => {
    dispatch({ type: TRAINERS_CREATE.REQUEST });
    const response = await postTrainer(values);
    dispatch({ type: TRAINERS_CREATE.SUCCESS, payload: response.data })
  }
}

