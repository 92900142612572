import React, { Fragment, useEffect, useState } from 'react';
import { getClientsByTrainerId, postChangeTrainer } from '../api/trainers';
import { CircularProgress } from '@material-ui/core';
import Alert from '../components/common/ToastrAlert';

import CustomContainer from '../components/UI/layout';
import Clients from '../components/Client';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const ClientsPage = ({match}) => {/*
    const { id: trainerId } = useParams();
    const {
        state: {
            trainer
        }
    } = useLocation();
    const { goBack } = useHistory();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
      setLoading(true);
      getClientsByTrainerId(trainerId)
        .then(clients => {
          setItems(clients);
          setLoading(false);
        })
    }, [trainerId]);

    const handleTrainerChange = (clientId, trainerId) => {
      postChangeTrainer(clientId, trainerId)
        .then(data => {
          const { message } = data;
          setMessage(message);
          setShowAlert(true);
          setItems(items.filter(item => Number(item.id) !== Number(clientId)));
        })
    };



    return (
        <Fragment>
            <CustomContainer onBack={goBack}>
                {
                    !loading ?
                        <Clients
                          items={items}
                          trainer={trainer}
                          onChangeTrainer={handleTrainerChange}
                        /> :
                        <CircularProgress color="secondary" />
                }
            </CustomContainer>
            <Alert
              open={showAlert}
              message={message}
              autoHideDuration={3000}
              severity={'success'}
              onClose={() => setShowAlert(false)}
            />
        </Fragment>
    );*/
};

export default ClientsPage;
