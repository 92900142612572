import {LEAD_STATUS} from "../../constants";
import {TableCell} from "@material-ui/core";
import React from "react";
import styles from "./scss/main.module.css"

export default function Status(props) {
    const {status} = props;
    const statusColor =
        status === 1 ? "#2196f3" : /*new*/
        status === 8 ? "#ff9800" :/* Contacted*/
        status === 2 ? "#ff9800" :/*In dialog*/
        status === 5 ? "#c280ff" :/* offer sendt*/
        status === 3 ? "#4caf50" :/* paid*/
        status === 4 ? "#f44336" :/*lost*/
        status === 9 ? "#4caf50" :/*assign trainer*/
        "grey"
    return (
        <>
            <span className={styles.status}
            style={{backgroundColor:statusColor}}
            >
                {LEAD_STATUS[status]}
            </span>
        </>
    );
}
