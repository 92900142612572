import client from "./base";

/**
 * @param string token
 * @returns {Promise<Object>}
 */
export function getClients() {
    return client.get('/clients');
}

/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getMoreClients(values) {
    return client.get('/clients',{params: {offset:values.offset,q:values.q}});
}

/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getClientsCount() {
    return client.get('/clients',{params: {count:true}});
}

/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSearchClientsCount(values) {
    return client.get('/clients',{params: {q:values,count:true}});
}
/**
 * @param string token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSearchClients(values) {
    return client.get('/clients',{params:{q:values}});
}

/**
 * @returns {Promise<Object>}
 * @param trainerId
 */
export const postClientChangeTrainer = async (values) =>{
    return client.post(`/trainer/move-client`, values).then(response => response.data)
        .then(data => ({message: data.msg}));
}


export const deleteClient = async (values) =>{

    return client.delete('', {params:{"id":values.id,"type":values.type}}).then(response => response.data)
        .then(data => ({message: data.msg}));
}

