import React, {useState} from 'react';
import Moment from "react-moment";
import moment from 'moment'
import {TableRow, TableCell, Tooltip} from '@material-ui/core';
import ItemAction from "../common/actions/ItemAction";
import ChangeTrainerAction from "../common/actions/ChangeTrainerAction";
import ManageActions from "../common/actions/index";
import styles from "./scss/main.module.css"
import {CLIENT_COMPLETED, MISSING_CHECKING} from "../../constants";
import DoneAllIcon from '@material-ui/icons/DoneAll';

const Client = (props) =>{
    const {item,trainers} = props
    const [showContent,setShowContent] = useState(false)
    const showContentFunc = () =>{
        setShowContent(!showContent)
    }
    const isCompleted = item.status.find(status => status.event_name === CLIENT_COMPLETED)
    const missedCheckin = item.status.find(status => status.event_name === MISSING_CHECKING)
    let didRespondWithin24h = false;

    const respondWithin24h = (item) => {
        const lastMessage = moment(item.messages.lastMessageSent);
        const today = moment(new Date())
        const difference = moment.duration(today.diff(lastMessage))
        const hours = difference.asHours()
        didRespondWithin24h = hours > 24 && item.messages.unansweredCount > 0
    }
    respondWithin24h(item);

    const getWeek = (item) => {
        let returned = ''
        const endDate = item.endDate != null && moment(item.endDate.date)
        const createdAt = moment(item.createdAt.date)
        const today = moment(new Date())
        const cal = endDate && endDate < createdAt ? endDate : createdAt

        /*active*/
        const duration = moment.duration(today.diff(cal));
        const weeks = Math.round(duration.asWeeks());

        /*ændres */
        if(item.endDate !== null && isCompleted ){
          return " Ended "+ weeks+" weeks ago"
        }else{
            return  weeks === 1 ? weeks +" week": weeks+" weeks"
        }
    }


    const message =
        <div className={styles.status}>
        { missedCheckin || didRespondWithin24h? (
                <span className={missedCheckin && didRespondWithin24h ? styles.danger: styles.warning }>
                    { missedCheckin && "Missed check-in"}
                    { missedCheckin && didRespondWithin24h && " & "}
                    { didRespondWithin24h && "No response 24h" }
                </span>
        ):""}
    </div>

    const completed = <span className={styles.completedMsg}> Completed </span>

    return (
        <>
            <TableRow className={ isCompleted ? styles.completed:""} >
                <TableCell className={styles.name}>
                    {item.name}
                </TableCell>
                <TableCell>
                    {getWeek(item)}
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>
                    <ManageActions>
                        <ChangeTrainerAction
                            item={item}
                            clientId={item.id}
                            currentTrainer={item.trainer.name}
                            availableTrainers={trainers}
                        />
                    </ManageActions>
                </TableCell>
                <TableCell >
                    <div className={styles.action}>
                        <ManageActions>
                            <ItemAction
                                item={item}
                                currentTrainer={item.user}
                                clientId={item.clientId}
                                availableTrainers={trainers}
                                type={"client"}
                            />
                        </ManageActions>
                        {!isCompleted && message}
                        {isCompleted && completed}
                    </div>
                </TableCell>
            </TableRow>
        </>
    );
}
export default Client;
