import React from "react";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/Button";
import DescriptionIcon from '@material-ui/icons/Description';

export default function ClientInfo(props) {

    const {item} = props;

    const clientInfoObj =  item.client && {
        'Answered questionnaire':item.client && item.client.answeredQuestionnaire,
        'Active':item.client.payments[0] && item.client.payments[0].active ,
        'Currency':item.client.payments[0] && item.client.payments[0].currency,
        'Recurring fee':item.client.payments[0] && item.client.payments[0].recurring_fee,
        'Upfront fee':item.client.payments[0] && item.client.payments[0].upfront_fee,
        'Months':item.client.payments[0] &&item.client.payments[0].months,
        'Terms':item.client.payments[0] &&item.client.payments[0].terms,
        'Trial end':item.client.payments[0] &&item.client.payments[0].trial_end,
    }

    const ClientInfoTemp = item.client && Object.entries(clientInfoObj).map((val,key) => {
        return (
            <div key={key}>
                <span>{val[0]}:</span>
                {
                    val[0] === "Terms" ?
                        (
                            <>
                                <Tooltip title={val[1] ? val[1] : ""}>
                                    <IconButton aria-label="delete" placement="top-start">
                                        <DescriptionIcon/>
                                    </IconButton>
                                </Tooltip>
                            </>
                        )
                    : val[0] === "Trial end" ?
                        (
                            <>
                                <span>{ val[1] ? Math.ceil(val[1]/60/60/60/60/24)+" days" : "ended" }</span>
                            </>

                        )
                    : val[0] === "Answered questionnaire" ||val[0] === "Active"?
                        (
                            <span>{val[1] ? "yes" : "no"}</span>
                        )
                    :(<span>{val[1] ? String(val[1]) : "no"}</span>)
                }
            </div>
        )
    });

    return (
        <>
            {item.status === 5 || item.status === 3 ?
                 ClientInfoTemp : "Offer is not sent"
            }
        </>
    )
}
