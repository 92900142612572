import {
    CLIENTS_FETCH,
    CLIENTS_CHANGE_TRAINER,
    CLIENTS_COUNT_FETCH,
    CLIENT_DELETE, CLIENTS_MORE_FETCH, CLIENTS_SEARCH, CLIENT_CLEAR_RESPONSE, OFFSET
} from "../constants";

const INITIAL_STATE = {
    clients: [],
    isLoading: false,
    clientChangeLoading:false,
    deleteClientLoading:false,
    isMoreLoading:false,
    offset:0,
    count:0,
    limit:0,
    q:'',
    response:'',
    responseStatus:false
};


export default function (state = INITIAL_STATE, {type, payload}) {
    switch (type) {

        case CLIENTS_FETCH.REQUEST:
        {
            return {
                ...state,
                isLoading: true,
                offset: 0,
                limit: 30
            };
        }

        case CLIENTS_FETCH.SUCCESS:
            return {
                ...state,
                clients: payload,
                isLoading: false
            };
        case CLIENTS_COUNT_FETCH.REQUEST:
        {
            return {
                ...state,
                count:state.count
            };
        }

        case CLIENTS_COUNT_FETCH.SUCCESS:
            return {
                ...state,
                count:payload.count
            };
        case CLIENTS_CHANGE_TRAINER.REQUEST:
        {
            return {
                ...state,
                clientChangeLoading: true,
            };
        }

        case CLIENTS_CHANGE_TRAINER.SUCCESS:
        {
            return {
                ...state,
                ...payload
            }
        }


        case CLIENTS_SEARCH.REQUEST:
            return {
                ...state,
                offset: 0,
                q:''
            };
        case CLIENTS_SEARCH.SUCCESS:
        {
            return {
                ...state,
                ...payload
            };
        }

        case CLIENTS_MORE_FETCH.REQUEST:
            return {
                ...state,
                isMoreLoading: true,
                q:state.q,
                limit:30
            };
        case CLIENTS_MORE_FETCH.SUCCESS:
        {
            return {
                ...state,
                ...payload
            };
        }
        case CLIENT_DELETE.REQUEST:
        {
            return {
                ...state,
                deleteClientLoading: true
            };
        }

        case CLIENT_DELETE.SUCCESS:
        {
            return {
                ...state,
                ...payload
            }
        }

        case CLIENT_CLEAR_RESPONSE.SUCCESS:
        {
            return {
                ...state,
                responseStatus:false
            }
        }


        default:
            return state;
    }
}
