import client from "./base"

/**
 * @param string token
 * @returns {Promise<Object>}
 */
export function getTrainers() {
  return client.get('');
}
/**
 * @param string token
 * @returns {Promise<Object>}
 */
export function postTrainer(token) {
  return client.post('', token);
}


const transformFromObjectToArray = data => Object.values(data);
