import {TRAINERS_FETCH, TRAINERS_CREATE, TRAINER_DELETE} from "../constants";

const INITIAL_STATE = {
    trainers: [],
    isLoading: false
};

export default function (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case TRAINERS_FETCH.REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case TRAINERS_FETCH.SUCCESS:
            return {
                ...state,
                trainers: payload,
                isLoading: false
            };
        case TRAINERS_CREATE.REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case TRAINERS_CREATE.SUCCESS:
            return {
                ...state,
                trainers: payload,
                isLoading: false
            };
        default:
            return state;
    }
}
