import {
  CLIENTS_FETCH,
  CLIENTS_CHANGE_TRAINER,
  CLIENT_DELETE,
  CLIENTS_SEARCH,
  CLIENTS_MORE_FETCH, CLIENT_CLEAR_RESPONSE, CLIENTS_COUNT_FETCH, WAIT, OFFSET,
} from '../constants';
import {
  getClients,
  postClientChangeTrainer,
  deleteClient,
  getSearchClients,
  getMoreClients,
  getClientsCount, getSearchClientsCount
} from "../api/clients";
import _ from 'lodash';

export const fetchClients = () => {
  return async dispatch => {
    dispatch({ type: CLIENTS_FETCH.REQUEST });
    const response = await getClients();
    dispatch({ type: CLIENTS_FETCH.SUCCESS, payload: response.data })
  }
}

const debounceIt = _.debounce(getClientsFunction,WAIT)

export const fetchMoreClients = () => {
  return async (dispatch,getState) => {
    dispatch({type: CLIENTS_MORE_FETCH.REQUEST});
    return debounceIt(dispatch,getState)
  }
}

async function getClientsFunction (dispatch,getState) {

  const body = {
    offset:getState().clients.offset+=OFFSET,
    q:getState().clients.q,
    limit:getState().clients.limit
  }
  const response = await getMoreClients(body);

  const payloaded = {
    clients: [...getState().clients.clients, ...response.data],
    isMoreLoading: false,
    offset: getState().clients.offset,
  }

  dispatch({ type: CLIENTS_MORE_FETCH.SUCCESS, payload: payloaded})
}



export const searchClients = (query) => {
  return async (dispatch,getState) => {
    dispatch({type: CLIENTS_SEARCH.REQUEST});
    const response = await getSearchClients(query);

    const payloaded = {
      clients: response.data,
      q:query,
      offset: 30
    }
    dispatch({ type: CLIENTS_SEARCH.SUCCESS, payload: payloaded})
  }
}

export const fetchCountClients = () => {
  return async dispatch => {
    dispatch({ type: CLIENTS_COUNT_FETCH.REQUEST });
    const response = await getClientsCount();
    dispatch({ type: CLIENTS_COUNT_FETCH.SUCCESS, payload: response.data })
  }
}

const debounceCount = _.debounce(getClientsCountFunction,WAIT)

export const searchClientCount = (value) => {
  return async (dispatch) => {
    dispatch({ type: CLIENTS_COUNT_FETCH.REQUEST });
    return debounceCount(dispatch,value)
  }
}

async function getClientsCountFunction(dispatch,value){
  const response = await getSearchClientsCount(value);
  dispatch({ type: CLIENTS_COUNT_FETCH.SUCCESS, payload: response.data })
}


export const clientChangeTrainer = (values) =>{
  return async (dispatch,getState) => {
    dispatch({ type: CLIENTS_CHANGE_TRAINER.REQUEST });

    const response = await postClientChangeTrainer(values);

    const changedTrainerName = getState().clients.clients.find(client => client.id === values.client).trainer.name =values.trainerName
    const payloaded = {
      clients: [...getState().clients.clients],
      clientChangeLoading: false,
      response: "Trainer was changed to '"+values.trainerName+"'",
      responseStatus: true
    }

    dispatch({ type: CLIENTS_CHANGE_TRAINER.SUCCESS, payload: payloaded })
  }
}

export const deleteClientAction = (values) => {
  return async (dispatch, getState) => {
    dispatch({ type: CLIENT_DELETE.REQUEST });
    const response = await deleteClient(values);

    const payloaded = {
      clients: [...getState().clients.clients.filter(client => client.id !== values.id)],
      deleteClientLoading:false,
      count:getState().clients.count - 1,
      response:"Client was deleted",
      responseStatus:true
    }

    dispatch({ type: CLIENT_DELETE.SUCCESS, payload: payloaded })
  }
}


export const clearClientResponse = () => {
  return async dispatch => {
    dispatch({ type: CLIENT_CLEAR_RESPONSE.SUCCESS})
  }
}


