import React, {Fragment, useEffect, useState} from 'react';
import {Table, TableBody, TableHead, TableCell, TableRow} from '@material-ui/core';
import Lead from "../../Lead";
import Trainer from "../../Trainer";
import Client from "../../Client";
import styles from './scss/main.module.css'
import Loader from "../loader";

export default function UniversalTable({tableTitles,tableType,trainers,isMoreLoading,items}) {
    /*table title*/
    const TableTitleCells = tableTitles.map((tableTitle,index) => {
        return (<TableCell key={index} style={{textTransform:"uppercase",borderBottom:0,textAlign:"left"}}>
            <strong> {tableTitle}  </strong>
        </TableCell>)
    });
    const tableBody = items.length > 0 ?  items.map(item => {
        return(
            tableType==="trainer" ?
                <Trainer
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    clients={item.clients}
                    token={item.token}
                />
                : tableType==="lead" ?
                <Lead
                    key={item.id}
                    item={item}
                    trainers={trainers}
                />
                : tableType==="client" ?
                    <Client
                        key={item.id}
                        item={item}
                        trainers={trainers}
                    />
                    :<></>
        )
    }): null
    return (
        <Fragment>
            { items.length > 0 ?
                (
                    <>
                        <Table size="small" className={styles.universalTable}>
                            <TableHead>
                                <TableRow >
                                    {TableTitleCells}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableBody}
                            </TableBody>
                        </Table>
                        {
                            isMoreLoading ?
                                <Loader color="secondary" />
                                : null
                        }

                    </>
                ) : (
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <p>No {tableType}s found</p>
                    </div>
                )
            }
        </Fragment>
    )

};
